import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
// import Box from 'components/box';
// import Title from 'components/title';
// import Gallery from 'components/gallery';
// import IOExample from 'components/io-example';
// import HeaderJumbotron from 'components/headerJumbotron';
// import BookingInputBox from 'components/bookingInputBox';
// import IconSection from 'components/iconSection';
import ZetaSection from 'components/zetaSection';
import ThankYouBox from 'components/thankYouBox';
import MapsSection from 'components/mapsSection';
import Footer from 'components/footer';
import { Container, Row, Col } from 'reactstrap';
// import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image';
const queryString = require('query-string');
import { graphql } from 'gatsby';
class Thankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoryMap: {
        19741: 'compact',
        19742: 'deluxe',
        19744: 'superior',
        19745: 'royal',
        19752: 'twin',
        19743: 'triple',
      },
      cart: {
        rooms: {
          compact: 0,
          deluxe: 0,
          superior: 0,
          royal: 0,
          twin: 0,
          triple: 0,
        },
      },
      params: {},
      headerText: 'Your booking is now Confirmed!',
      bookingInfoText: '',
    };
  }
  // componentWillMount = () => {
  //   var urlParams = queryString.parse(this.props.location.search);

  //   if (!urlParams.rooms) {
  //     this.setState({
  //       params: urlParams,
  //       headerText: 'Sorry, Your Booking was not confirmed ',
  //     });
  //   } else {
  //     this.setState({ params: urlParams });
  //   }
  // };
  render = () => {
    return (
      <Layout>
        <Container style={{ marginTop: '2.5rem' }}>
          <h1 style={{ textAlign: 'center', fontFamily: 'Aller' }}>
            {this.state.headerText}
          </h1>
        </Container>
        <ThankYouBox
          checkin={this.state.params.checkin}
          checkout={this.state.params.checkout}
        />
        {/* <ZetaSection /> */}
        <Container style={{ marginTop: '2.5rem' }}>
          <h1 style={{ textAlign: 'center', fontFamily: 'Aller' }}>
            Directions to the hotel
          </h1>
        </Container>
        <MapsSection />
        <Footer />
      </Layout>
    );
  };
}

export default Thankyou;

export const query = graphql`
  query {
    rgsl: file(
      relativePath: { eq: "images/gallery/rajiv-gandhi-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bwsl: file(
      relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concept: file(relativePath: { eq: "images/gallery/concept.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file(relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
