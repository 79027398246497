import React from 'react';
import './thankYouBox.css';
import { StaticQuery, graphql } from 'gatsby';
// import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import { Row, Col, Container, Button } from 'reactstrap';
class ThankYouBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // title: this.props.title,
      // description: props.description,
      // buttonText: props.buttonText,
      // buttonLink: props.buttonLink,
      // image: props.image,
      checkin: props.checkin,
      checkout: props.checkout,
    };
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            royal: file(
              relativePath: {
                eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Container
              style={{
                backgroundColor: 'rgb(244,242,239)',
                padding: '20px',
                marginTop: '4rem',
                marginBottom: '4rem',
              }}
            >
              <Row>
                <Col>
                  <div style={{ padding: '20px' }}>
                    <h2 style={{ fontFamily: 'AllerLight' }}>
                      {/* {this.state.title}. */}
                    </h2>
                    <h3 style={{ fontSize: '20px', fontFamily: 'AllerLight' }}>
                      {/* {this.state.description} */}
                      {/* Check-in : {this.state.checkin}
                      <br />
                      Check-out : {this.state.checkout} */}
                      You will receive an email shortly with the booking details
 <br /> <br />
 For Further details and confirmation on Airport pickup & drop, Please drop us an email at hotelgracegalaxy@gmail.com
  <br />
Or <a href="https://wa.me/+917718800522">whatsapp us at +91 7718800522</a>
                    </h3>
                    <div
                      style={{
                        marginTop: ' 45%',
                        width: '145px',
                        fontFamily: 'AllerLight',
                      }}
                    >
                      {/* {this.state.buttonText} */}
                    </div>
                  </div>
                </Col>

                <Col>
                  <div>
                    <Img
                      style={{ borderRadius: '4px' }}
                      fluid={data.royal.childImageSharp.fluid}
                    ></Img>
                    *Image of Royal Room, Rooms of other category do not look the same. 
                  </div>
                </Col>
              </Row>
              {/* <div style={{ margin: '0 auto', width: '145px' }}>
            <Button id="zetaSectionButton">KNOW MORE</Button>
          </div> */}
            </Container>
          </div>
        )}
      />
    );
  }
}
export default ThankYouBox;
